<template>
  <div class="h-px print:!hidden" ref="pinner" />
  <header
    class="bg-white py-3 md:py-6 border-b border-gray-300 sticky origin-top top-0 transform-gpu transition-all duration-300 z-[9999] print:!hidden"
    :class="{
      'scale-y-[calc(56/66)] md:scale-y-[calc(56/98)]': headerIsPinned,
    }"
    ref="header"
  >
    <div>
      <!-- Scale is opposite of scale of <header>. When header scales down, content should scale up to avoid getting squished. -->
      <div
        class="container transform-gpu transition-all duration-300 relative flex flex-row items-center justify-between"
        :class="{
          'scale-y-[calc(1/(56/66))] md:scale-y-[calc(1/(56/98))]':
            headerIsPinned,
        }"
      >
        <div
          id="toggle-header-menu"
          class="p-3 -ml-3 md:-mr-3 z-50 cursor-pointer"
          @click="toggle(1)"
        >
          <div
            class="toggle transition-opacity duration-100"
            :class="[
              {
                'opacity-35': activeMenu && activeMenu !== 1,
              },
              { open: activeMenu === 1 },
            ]"
          >
            <span />
            <span />
            <span />
          </div>
        </div>

        <div class="z-50 md:ml-5 flex items-center">
          <NuxtLink
            :to="{ name: 'frontpage' }"
            class="flex items-center shrink-0"
          >
            <div
              class="origin-left inline-flex relative transform-gpu transition-all duration-300"
              :class="{
                'h-[30px]': headerIsPinned,
                'h-[40px]': !headerIsPinned,
              }"
            >
              <img
                v-if="sagaPaperStore.isFrontPagePaper || true"
                src="/A_Logo.svg"
                alt="Altinget logo"
                :class="{
                  'hidden lg:block':
                    true && !sagaPaperStore.isFrontPagePaper,
                }"
              />
              <img
                v-if="!sagaPaperStore.isFrontPagePaper"
                src="/A_Logomark.svg"
                alt="Altinget logo"
                class="aspect-square w-12"
                :class="{
                  'lg:hidden': true,
                }"
              />
            </div>
          </NuxtLink>
          <NuxtLink
            v-if="!sagaPaperStore.isFrontPagePaper"
            :to="{
              name: 'paper',
              params: { paper: sagaPaperStore.activePaper.slug },
            }"
            class="flex ml-2 items-center font-bold text-blue list-title-m"
          >
            {{ sagaPaperStore.activePaper.name }}
          </NuxtLink>
        </div>

        <!-- Primary menu. Hide on mobile -->
        <ul class="hidden xl:flex items-center gap-5 ml-6">
          <template v-for="item in menuItems" :key="item.label">
            <li>
              <NuxtLink
                :to="`${getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)}`"
                class="font-sans text-lg hover:text-blue"
                active-class="text-blue"
              >
                {{ $t(item.label) }}
              </NuxtLink>
            </li>
          </template>
          <li class="items-center cursor-pointer flex">
            <input
              ref="search"
              v-model="searchWords"
              type="text"
              class="outline-none rounded-full text-blue bg-white transition-all duration-300 body-m py-2"
              :class="searchOpen ? 'w-64 -mr-8 px-4 bg-gray-100 ' : 'w-0'"
              :placeholder="$t('Search')"
              @keyup.enter="onSearch"
            />
            <SearchIcon
              class="w-6 h-6 transition-all duration-200 fill-current hover:text-blue"
              :class="searchOpen ? 'text-blue' : null"
              @click="searchIconClick"
            />
          </li>
        </ul>

        <LayoutMenuAltinget v-if="activeMenu === 1" />
        <div class="md:ml-auto">
          <LayoutUser />
        </div>
      </div>
    </div>
  </header>
  <div
    v-if="isSponsoredRoute && indexStore.headerState.sponsoredBannerActive"
    class="sticky top-0 bg-[#020136] font-sans text-white text-2xl py-3 font-bold z-50"
    :class="headerIsPinned ? 'top-[53px]' : null"
  >
    <div class="container">
      <span class="md:px-11">{{ $t('ads.nativeAds') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import SearchIcon from '~/assets/icons/search.svg?component'

const nuxtApp = useNuxtApp()
const route = useRoute()

const sagaPaperStore = useSagaPaperStore()
const indexStore = useIndexStore()

const primaryMenuItems = getPrimaryMenuItems()
const paperMenuItems = getPaperMenuItems()
// Computed to update automatically on route change
const menuItems = computed(() => {
  return route.params.paper ? paperMenuItems : primaryMenuItems
})
const routePrefix = computed(() => {
  return route.params.paper ? `/${route.params.paper}/` : '/'
})

const isSponsoredRoute = computed(() => {
  return route.name === 'sponsored_id' || route.name === 'sponsored_paper_id'
})

const activeMenu = ref()
const searchWords = ref('')
const search = ref<HTMLInputElement>()
const searchOpen = ref(false)

// Ref to header element
const pinner = ref<HTMLElement>()

// Is the header currently sticky?
const headerIsPinned = ref(false)

const waiting = ref(false)

const scaleHeader = ([e]: IntersectionObserverEntry[]) => {
  headerIsPinned.value = e.intersectionRatio < 1
  waiting.value = true
}
onMounted(() => {
  const observer = new IntersectionObserver(([e]) => scaleHeader([e]), {
    threshold: [1],
  })
  if (!pinner.value) return
  observer.observe(pinner.value)
})

const toggle = (menu: number) => {
  activeMenu.value = activeMenu.value === menu ? null : menu
}

const onSearch = async () => {
  await navigateTo({
    name: sagaPaperStore.isFrontPagePaper ? 'searchwords' : 'paper_searchwords',
    params: {
      words: searchWords.value,
      paper: sagaPaperStore.isFrontPagePaper
        ? undefined
        : sagaPaperStore.activePaper.slug,
    },
  })
}

const searchIconClick = () => {
  if (searchOpen.value) {
    onSearch()
  } else {
    search.value?.focus()
    searchOpen.value = true
  }
}

watch(
  () => route.path,
  () => {
    activeMenu.value = null
    searchOpen.value = false
  }
)
</script>

<style lang="scss" scoped>
.toggle {
  position: relative;
  height: 14px;
  width: 24px;
  span {
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #000;
    transition: all 200ms;
    top: 0;
    left: 0;
    &:nth-child(2) {
      top: 6px;
    }
    &:nth-child(3) {
      top: 12px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 6px;
        left: 0;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 6px;
        left: 0;
      }
    }
  }
}
</style>
