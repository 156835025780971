<template>
  <div
    class="flex menu flex-col md:flex-row w-full md:w-9/12 lg:w-8/12 h-screen overflow-scroll md:overflow-auto md:h-auto grid-cols-5 z-40 md:-ml-3 absolute left-0 md:left-auto bg-white md:shadow md:rounded-lg py-4 md:pr-0 transition-all duration-200 transform"
  >
    <div class="w-full py-6">
      <div class="label-m mb-5 pl-5 md:pl-12">
        {{ $t('Papers') }}
        <NuxtLink
          class="ml-3 opacity-35 hover:opacity-100 hover:text-blue transition-all duration-100"
          :to="{ name: 'papers' }"
        >
          {{ $t('ShowAll') }}
        </NuxtLink>
      </div>
      <LayoutSwipeList
        :mobile-rows="2"
        :space-between="15"
        :small="true"
        :container="false"
        :fade="true"
        class="swiper"
      >
        <SwiperSlide
          v-for="(menuItem, index) in sagaPaperStore.nichePapers"
          :key="index"
        >
          <PaperTeaser :paper="menuItem" type="small" />
        </SwiperSlide>
      </LayoutSwipeList>
      <div class="flex flex-col md:flex-row mt-10">
        <div class="md:columns-3 px-5 md:pl-12 w-full md:w-2/3">
          <ul v-if="menu" class="body-s">
            <li
              v-for="(item, index) in menu.header"
              :key="index"
              class="md:break-after-column"
            >
              <CommonDivider
                v-if="index === 0"
                class="md:hidden -mx-5 px-5 mb-8"
              />
              <div class="body-xs font-bold uppercase mb-5 text-blue">
                {{ item.headline }}
              </div>
              <ul>
                <li
                  v-for="(subitem, subItemIndex) in item.submenu"
                  :key="'submenu' + subItemIndex"
                >
                  <NuxtLink
                    v-if="subitem.link.indexOf('https') == -1"
                    :to="subitem.link"
                    class="list-title-xxxs mb-3 !font-medium inline-block"
                  >
                    {{ subitem.headline }}
                  </NuxtLink>
                  <a
                    v-else
                    :href="subitem.link"
                    class="list-title-xxxs mb-3 !font-medium inline-block"
                  >
                    {{ subitem.headline }}
                  </a>
                </li>
              </ul>
              <CommonDivider class="md:hidden -mx-5 px-5 mb-8" />
            </li>
          </ul>
        </div>
        <div class="w-full md:w-1/3">
          <div
            class="pr-0 pl-0 md:pr-24 md:pl-10 text-center mt-8 md:mt-0 px-8 md:px-0"
          >
            <div class="flex items-center flex-col">
              <img
                src="@/assets/icons/promoicon_conversation_light.svg?url"
                class="h-8 w-auto mb-2"
              />
            </div>
            <div class="promo-title-menu">
              {{ $t('14DaysTrial') }}
              <div class="font-light">
                {{ $t('Ourmedia') }}
              </div>
            </div>
            <NuxtLink :to="{ name: 'subscription' }">
              <CommonButton
                bg="bg-blue"
                text="text-white"
                border="border-none"
                class="mt-6"
                size="medium"
              >
                {{ $t('ReadMoreAndSignup') }}
              </CommonButton>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'

const indexStore = useIndexStore()
const sagaPaperStore = useSagaPaperStore()

const menu = computed(() => indexStore.menu)
</script>

<style lang="scss" scoped>
.menu {
  height: calc(100vh - 65px);
  top: 65px;
  @screen md {
    top: 100%;
    height: auto;
  }
}
</style>
